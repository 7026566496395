.description1 {
    font-family: 'Poppins';
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #383838;
}



.icon-box {
    /*  width:28% !important; */
    padding-bottom: unset;
}


.home-page-icon {
    box-shadow: 7.1px 7.1px 16px 0 rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 32px 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.whyira {
    /*  margin-left: -20px; 
    padding-left: 2%;*/
    font-family: 'MADE Coachella';
    font-style: normal;
    color: #362A82;

}

.title1 {
    /* padding-left: 8%;
    font-weight: 700;
    width: 225px;*/
    /* height: 72px;  */
    font-family: 'Poppins' !important;

    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    letter-spacing: 0.03em;
    color: #383838;
}


/* --------------Spesification----------------- */

.specification_sec {
    width: 100%;
}

.specifications_title {
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #383838;
    margin-top: 6%;
    margin-bottom: 4%;
}

.specifications_description {

    line-height: 27px;
    letter-spacing: 0.01em;
}

.specifications_row {
    margin-top: 5% !important;
}

/* ---------------Revolution------------------ */

.society {
    margin: 30px 100px 30px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.7;
    letter-spacing: .8px;
    text-align: left;
    color: #383838;
    position: absolute;
    width: 1125px;
    height: 504px;


}

/* -------------------Aspire------------------------- */
.container-box {
    padding-top: 7%;
}

.aspire-title {
    text-align: inherit;
}

.img-box {
    height: 60%;
    margin-top: 19%;
    margin-left: 7%;

}

.aspire_button {
    text-decoration: none;
    background-color: #362A82 !important;
    align-items: center;
    padding: 11px;
    color: #ffff;
    border-radius: 4px;
    margin-right: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.downloadsection {
    gap: 6px;

}

.downloadsection a:hover {
    color: #fff;
    padding-left: 0% unset;

}

.svg {
    margin-right: 10px;

}

/*----------------------IraMiracle----------------------*/
.LiveProgress_title {
    color: #362A82;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    letter-spacing: 0.03em;
    font-family: 'MADE Coachella';
}

.LiveProgress_description {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #000000;
}

.container_image {
    background: #FCF3EF;
    padding: 6% 0;


}


.LiveProgress_background_img {

    height: 100%;

    background-image: url(../public/assets/backgroud_img.png);
    background-size: 100% 100%;
    padding-top: 12%;
}

.LiveProgress_img {
    border-radius: 12px;
   /*  margin-top: 8%; */
    object-fit: cover;
    width: 100%;
}

.finishStatus{
    position: absolute;
    background: #27AE60 !important;
    border-radius: 16px;
    height: 12px;
    z-index: 1;

}
.planStatus{
    background: #FFBE00 !important;
    border-radius: 16px;
}

.ProgressBar_white {
    position: relative;
    height: 12px !important;
    width: 100%;
    background: #FFFFFF !important;
}
.body_style {
    /* padding-left: 5% !important; */
    padding: 0px 16px;
}
.Struc_perse{
    display: flex;
    /* padding-left: 57%; */
    gap: 24px;
    float: right;
}

.carousel-control-next {
    top: 40px;
    right: 23px;
    color: #fff;
}

.carousel-control-prev {
    top: 40px;
    left: 20px;
    color: #fff;
}
.Circle{
    display: flex;
    padding-left: 70%;
    gap: 24px
}
.Structures_Circle{
    display: flex;
    padding-top: 30px;
    /* gap: 156px */
    
    
}
.Finishes_Circle{
    display: flex;
    /* gap: 175px; */
}
.Amenities_Circle{
    display: flex;
  /*   gap: 156px; */
    padding-top: 40px;
}
.paragrapg{
    font-weight: 500;
    color: #383838;
    /* width: 15%; */
}

.plan_font{
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
}
.Finished_font{
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
}
.Circle_Plan{
    color: #FFBE00; 
    width:8px;
    margin-top: -4px;
}
.Finished_Circle{
    color: #27AE60;
    width:8px;
    margin-top: -4px;
}
.persentsge_color{
    font-weight: 600;
    color: #7B5F18;
    padding-left: 4px;

}
.year{
    font-weight: 600;
    color: #7B5F18;
} 

@media (max-width: 2560px) {
    .LiveProgress_ProgressBar {
        width: 19%;
    }

    .carousel-control-next {
        right: 30px !important;

    }

    .carousel-control-prev {
        left: 30px !important;
    }

    .Circle{
        /* padding-left: 86%; */
    }
    /* .Structures_Circle, .Amenities_Circle{
        display: flex;
        gap: 83%;
       
    }
    .Finishes_Circle{
        display: flex;
        gap: 84%;
       
    } */
    /* .Struc_perse{
        padding-left: 72%;
    } */

}

@media (max-width: 1920px) {
    .LiveProgress_ProgressBar {
        width: 26%;
    }
}

@media (max-width: 1440px) {
    .LiveProgress_ProgressBar {
        width: 35%;

    }
    .LiveProgress_title{
        padding-right: 14%;
    }

    .carousel-control-prev {
        top: 52px;
        left: 28px !important;
    }

    .carousel-control-next {
        right: 26px !important;
        top: 52px;
    }

    .Circle{
        padding-left: 76%;
    }
    /* .Struc_perse{
        padding-left: 62%;
    } */
}

@media (max-width: 1326px) {
    .LiveProgress_ProgressBar {
        width: 38% !important;
    }

    .carousel-control-prev {
        left: 31px;

    }

    .carousel-control-next {
        right: 30px;

    }
}

@media (max-width: 1024px) {
    .LiveProgress_ProgressBar {
        width: 44% !important;

    }
    .Circle{
        padding-left: 68%;
    }
    /* .Struc_perse{
        padding-left: 53%;
    } */
   

    
}

@media (max-width: 991px) {
    .LiveProgress_title {
        padding-right: unset;
    }
    .body_style {
        padding: 0px 16px;
    }
    .Circle{
        padding-left: 84%;
    }
    /* .Struc_perse{
        padding-left:69%;
    } */
   
    .LiveProgress_img {
        height: 30%;
    }

    .LiveProgress_description {
        text-align: center;
    }

    .LiveProgress_ProgressBar {
        width: 93% !important;
    }

    .carousel-control-prev {
        top: 130px !important;
        left: 17px !important;
    }

    .carousel-control-next {
        right: 37px !important;
        top: 130px !important;

    }
    .LiveProgress_title{
        text-align: center;
    }
    
}

.aspire-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #000000;
    opacity: 0.6;
}

@media (max-width: 960px) {

    .whyira {
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
    }

    .body-page {
        width: 114% !important;
        text-align: center;
    }

    .home-page-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0px;
        gap: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        margin-left: 0%;

    }

    .title1 {
        font-weight: 700;
        text-align: center;

    }

    .description1 {
        text-align: center;

    }

    .title_box {
        padding-left: 5%;
        height: 7px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }

    .specifications_row {
        margin-top: 5% !important;
    }

    .accordion-button {
        padding: 0px !important;

    }

    .accordion-button:focus {
        box-shadow: 0px !important;
        border-color: unset !important;
        box-shadow: unset !important;

    }

    .accordion-button:not(.collapsed) {
        color: unset !important;
        background-color: unset !important;
        box-shadow: unset !important;
    }

    .accordion-body {
        padding-left: 6% !important;
    }

    .accordion-button::after {
        background-image: MdArrowDropDown;
    }

    /* This css used in  Aspire page ----------------------------*/

    .aspire-title {
        height: 64px;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.03em;
        text-align: center !important;
    }

    .aspire-description {
        max-width: 100%;
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.02em;
    }

    .aspire_button {
        width: 100%;

        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 11px 14px;
        gap: 4px;
        max-width: 100%;
        margin-top: 5%;
        height: 42px;
    }

    .downloadsection {
        display: contents;
    }

    .background_img {
        margin-top: 9% !important;
        margin-left: 0;
    }

    .body-page .card-body {
        margin: 5%;
    }


    /* ------------LiveProgress start-960----------------- */
    .LiveProgress_title {
        text-align: center;
        font-size: 24px;
    }

    .LiveProgress_description {
        text-align: center;
        font-size: 14px;
    }

    .project_status {
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
    }

    .start_finish {
        padding-top: 40px;

    }

    .LiveProgress_img {
        margin-top: -2%;
        margin-left: -10%;
    }

    .carousel-control-next {
        top: -10px !important;
    }

    .carousel-control-prev {
        top: -10px;
        left: 2px;
    }
}

/* -----------------LiveProgress_768--------------- */
@media (max-width: 768px) {
    
    .LiveProgress_img {
        margin-left: 2%;
    }

    .carousel-control-next {
        top: -10px;
        right: 20px !important;

    }

    .carousel-control-prev {
        top: -10px !important;
        left: 40px !important;
    }

    .LiveProgress_description {
        text-align: center;
    }

    .LiveProgress_ProgressBar {
        width: 93% !important;
    }
    .Circle{
        padding-left: 78%;
    }
    /* .Struc_perse{
        padding-left: 65%;
    } */
   
}



@media (max-width: 425px) {

    .carousel-control-next {
        top: -10px;
        right: 24px !important;
    }

    .carousel-control-prev {
        top: -10px;
        left: 40px !important;
    }

    .LiveProgress_description {
        text-align: center;
    }

    
    .Circle{
        padding-left: 62%;
    }
    /* .Struc_perse{
        padding-left: 49%;
    } */
    
   
}
@media (max-width: 403px) {
    .Circle {
        padding-left: 60%;
    }
}

@media (max-width: 375px) {
    .LiveProgress_description {
        text-align: center;
    }

    .carousel-control-next {
        right: 47px;
    }

    .carousel-control-prev {
        left: 26px;
    }
    .Circle{
        padding-left: 57%;
    }
    /* .Struc_perse{
        padding-left: 44%;
    } */
   

}

@media (max-width: 320px) {
    .Finishes_Circle {
        gap: 38%;
    }
    .Structures_Circle, .Amenities_Circle {
        gap: 32%;
    }
    .body-page {
        width: 100%
    }

    .LiveProgress_description {
        text-align: center;
    }

    .carousel-control-prev {
        left: 36px !important;
    }

    .carousel-control-next {
        right: 27px !important;
    }
    .Circle{
        padding-left: 49%;
    }
    /* .Struc_perse{
        padding-left: 36%;
    } */
   

}