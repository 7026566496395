#parent2 {
  position: relative;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  height: 399px;
 
}

.blog_background_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


#child2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 10px;

  position: absolute;
  width: 40%;
  left: 5%;
  top: 31%;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 6px;
}




.Blogs {


  width: 380px;
  height: 24px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.01em;
  text-transform: uppercase;

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

}

.Blogs-sub-text {


  font-family: 'MADE Coachella';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;



}

.Join-IRA-Today {
  padding: 36px 0;
  background-color: #fdf1ed;
  /* margin-bottom: 36px; */
  margin-top: 60px;

}

.blog-Rectangle-10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 32px;
  width: 350px;
  height: 368px;
  margin-top: 20px;
  background: rgba(243, 129, 92, 0.2);
  border-radius: 8px;
}

.Blog-Categories {
  width: 117px;
  height: 30px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.Bolg-cat-name {

  height: 24px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.02em;

  /* Ira-Light/Menu Text */

  color: #222222;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0
}

.card-category {
  margin: 12px 0px 0px 35px;
  font-display: swap;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.56px;
  text-align: left;
  color: #383838;
}

.blog-icon-box {
  width: 352px;
  height: auto;
  margin: 25px auto;
  text-align: left;
  transition: all .3s ease-in-out;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 8px;

}

.blog-icon-box:hover {
  cursor: pointer !important;
  border-color: #fff !important;
  box-shadow: 0 0 25px 0 rgb(16 110 234 / 10%) !important;
}

.blog-list-img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-left: 0;
  width: 100%;
  height: 185px;
  color: #3b8af2;
  font-size: 28px;
  transition: .3s ease-in-out;
}

.blog-list-title {
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  margin: 10px 30px 15px 36px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: .64px;
  color: #362a82;

}

.blog-card-description {
  margin: 15px 16px 15px 36px;
  opacity: .6;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.93;
  letter-spacing: .28px;
  color: rgba(0, 0, 0, .6);
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  text-align: left;

}

.blog-card-founder-name {
  margin: 15px 87px 10px 36px;
  color: #362a82;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: .56px;
  text-align: left;

}

.blog-read_link {
  margin: 25px 8px 0 180px;
  font-size: 14px;
  font-weight: 400;
  line-height: 3.14;
  letter-spacing: .28px;
  color: #362a82;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  border: none;
  background-color: transparent;
}

.Blogs-Detail {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.blog-Rectangle-10-small {
  display: none;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 2px 7px;
  background: rgba(243, 129, 92, 0.2);
  border-radius: 8px;

}

.blog-filter-icon {

  Width: 25.52px;
  Height: 30px;
  color: #362A82 !important;
  padding: 2px;
  margin-top: 3px;
  border-radius: 5px;

}

.Blog-Categories-small {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 4px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #000000;

}

.media_drop_down {
  padding: 8px 1px;

width: 84px;
height: 40px;


/* Light/Light_F */

background: #FFFFFF;
/* Light/Light_C */

border: 1px solid #CCCCCC;
border-radius: 6px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */


/* Ira-Light/Primary Colour */

color: #362A82 !important;
}

.media-portfolio-wrap {
  transition: .3s !important;
  position: relative;
  overflow: hidden !important;
  text-align: center;

}

.media-portfolio-wrap:hover:before {
  opacity: 1;


}
.media-portfolio-wrap:hover .media-portfolio-info {
  opacity: 1 !important;


}


.form-check-input:checked {
  background-color: #362A82 !important;
  border-color: #362A82 !important;
}

.form-check-input {
  width: 17px !important;
  height: 17px !important;
  margin-right: 10px !important;
}

.media-portfolio-wrap:before {
    content: "";
    background: rgba(54,52,58,.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all .3s ease-in-out;
    z-index: 1;
    opacity: 0;

}
.media-portfolio-wrap:hover .media_image{
  transform: scale(1.2);
}

.media_portfolio_description {
  color: hsla(0, 0%, 100%, .7);
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-align: left;
}
.join_tag_line{
  font-size: 20px;
  font-weight: 700;
}

.media_image {
  transition: all .3s ease-in-out;
  padding: 0;
  height: 272px;
  margin-bottom: 20px;
  max-width: 100%;
}

.media-portfolio-info {
  
  opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    transition: all .3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;

}


.footer {
  font-size: 14px;
  padding: 0px;
}
.error {
  color: red;
  margin: 2px;
  font-size: 12px;
}


.footer {
  font-size: 14px;
  background: #F7F7FA;
 
}

.footer-link-para {
  font-size: 14px;
  line-height: 20px;
  letter-spacing:  0.03em;
  margin-bottom: 0;
  font-display: swap;
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  color: #777777;

}

.footer-header {

  height: 20px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #444444;
}

  .social-media-icons{
    font-size: 25px;
    margin-right: 25px;
    cursor: pointer;
    color: #362a82;
  }
  
  .social-media-icons:hover{
    color: #777777;
  }
  
  .enquire_input_box {
    font-family: 'Poppins';
font-style: normal;
font-weight: 400 !important;
font-size: 14px !important;
line-height: 20px !important;
/* identical to box height */




  }
  .form_text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px !important;
line-height: 18px;
/* or 150% */



  }
  .PhoneInputInput{
    border: none;
    height: 47px;
  }
  .PhoneInputCountryIcon{
    /* display: none; */
  }
  .PhoneInput{
    background: #fff;
    border-bottom: 1px solid #000;
  }
  .PhoneInputCountry{
    padding-left: 10px;
  }
  .cancel_btn1{
   

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    letter-spacing: 0.08em;

    /* Ira-Light/Primary Colour */

    color: #362A82;

    padding: 8px 16px;
    gap: 10px;

    /* width: 89px;
    height: 34px; */
    /* margin: 24px 0px; */

    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #362A82;
  }

.submit_btn1{
    padding: 8px 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.08em;
    background: #362A82;
    border-radius: 4px;
    border-color: transparent !important;
    color: #fff;
  }

  .submit_btn_disabled{
    padding: 8px 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    letter-spacing: 0.08em;
    opacity: 0.4;
    background: #362A82;
    border-radius: 4px;
    border-color: transparent !important;
    color: #fff;
  }

  .footer-ira-reality {
    color: #777777;
    padding: 4px 0px;
    transition: .3s;
    display: inline-block;
    text-decoration: none;
  }

  .footer-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 38px 24px;
    gap: 16px;

    width: 328px;
    height: 510px;

    background: #EBEAF2;
    border-radius: 8px;
  }

  .footer-form-descreption {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 0px 24px;
    color: #444444;
  }

.social-media-icons {
  font-size: 25px;
  margin-right: 25px;
  cursor: pointer;
  color: #362a82;
}

.social-media-icons:hover {
  color: #777777;
}

.media-portfolio-container{
  height: auto;
  margin-bottom: 30px;
}

/* Parent Container */
.content_img{
  position: relative;
  overflow: hidden;
  height: auto;
  float: left;
  margin-bottom: 30px;
 }
 
 
 /* Child Text Container */
 .content_img div{
  position: absolute;
  top: 1%;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.45);

  color: white;
  margin-bottom: 5px;
  font-family: sans-serif;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear; 
  transition: visibility 0s, opacity 0.5s linear;
 }
 
 /* Hover on Parent Container */
 .content_img:hover{
  cursor: pointer;
 }
 
 .content_img:hover div{
  width: 100%;
  padding: 150px 30px 0px 30px;
  visibility: visible;
  opacity: 0.7; 
 }
 .media_link{
  padding: 8px 17px 9px;
  gap: 10px;
  
  width: 127px;
  height: 40px;
  text-decoration: none;
  /* Ira-Light/Primary Colour */
  color: #fff;
  background: #362A82;
  border-radius: 4px;
  transition: .5s;
    
    animation-delay: .8s;
 }
 .media_link:hover{
  color: #fff !important;
 }

 @media (max-width: 991px) {
  .join_revolution_data{
    display: none;
  }
  
 }
 
 @media (min-width: 991px) {
  .join_revolution{
    display: none !important;
  }
 }
 .join_revolution{
  display: block;
}

@media (max-width: 960px) {
  .blog-Rectangle-10-small{
    display: flex;
  }
  
  #child2{
    width: 80% !important;
    top: 35%;
    left: 6%;
    padding: 24px 11px;
  }
  .Blogs-sub-text{
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  #child2{
    top:22%
  }
}
@media (min-width: 960px) {
  .footer {
    padding: 0% 8%;
  }
  
}


@media (max-width: 960px) {
  .blog-Rectangle-10 {
    display: none !important;
  }
  
  .media_header{
    width: 156px;
height: 64px;
text-align: left;

  }
  .join_tag_line{
    font-size: 14px;
  }
  
}
@media (min-width: 1024px) {
  #child2{
    left: 11%;
  }
  .footer {
    padding: 0% 10% !important;
}
}

@media (min-width: 1440px){
  .footer {
    padding: 0% 12% !important;
}
}
@media (min-width: 1900px){
  #child2 {
    left: 21%;
}


.footer {
    padding: 0 22%!important;
}
}

@media (min-width: 2560px) {
  #child2{
    left: 30%;
  }
  .footer {
    padding: 0% 29% !important;
}
}


