

@media (max-width: 992px) {
    .Rectangle-24 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: .28px;
        text-align: center;
        color: #fff;
        position: absolute;
        width: 118px;
        height: 38px;
        padding: 8px;
        background-color: #362a82;
        border-top-left-radius: 8px;
    }
    
    
    .featured-project-location-mobile-dropdown {
        width: 50%;
    }

    .Featured-Projects-card2-background {
        margin: auto !important;
    }

    .featured-projects-mobile-view {
        font-family: Poppins;
        margin-bottom: 80px;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: .28px;
        text-align: left;
        color: #007bff;
    }

    .featured-projects-mobile-view .prev {
        position: absolute;
        left: 71px;
    }

    .featured-projects-mobile-view .next {
        position: absolute;
        right: 71px;
    }

    .featured-project-mobile-dropdown {
        margin-left: 30px;
        margin-top: 16px;
        display: flex;
    }

    .featured-projects-dropdown .dropbtn {
        border: 1px solid #d6d6d6 !important;
        background-color: #f8f9fa !important;
        width: auto !important;
    }

    .featured-project-mobile-card {
        margin: 21px 16px 43px 30px;
    }

    .dropbtn {
        background-color: #f8f9fa !important;
        border: 1px solid #d6d6d6 !important;
        width: auto !important;
    }

    .dropdown-toggle::after {
        display: inline-block !important;
    }
}

@media (max-width: 991px) {

    .project_highlights_main .description2 {
        width: auto !important;
    }


    .plans .plans-Rectangle {
        margin-left: 10px !important;
        background-color: transparent !important;
        width: auto !important;
        height: auto !important;
    }

    .active_plans_div {
        background-color: rgba(243, 129, 92, 0.2);
        margin-top: 10px !important;
        height: 147px !important;
        cursor: pointer;

    }

    .active_plans {
        background-color: transparent !important;
        cursor: pointer;
    }
}

.read_more_btn {
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-decoration: none;
    color: #362A82 !important;
}

iframe {
    /* border-width: 2px !important; */
    border-style: inset !important;
    border-color: initial !important;
    border-image: initial !important;
}

@media (max-width: 575px) {
    .projecticon-box {
        display: flex;
        padding-bottom: 10%;
    }

    .specifications_icon {
        width: 32px;
        height: 32px;
    }

    .projecticon img {
        width: 56px;
        height: 56px;
    }

    .project_highlights_mobile {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 7px;
        width: 200px !important;
        flex: none;
        order: 1;
        flex-grow: 1;
    }

    .project_highlights_main .projectdetails_description {
        width: auto !important;
    }
    .project_highlights_main .description3{
        width: 300px;
        text-align: left;
    }
}

@media (max-width: 960px) {
    .banner_image .card {
        width: 83% !important;
    }



    /* .featured_projects_title {
        font-size: 24px !important;
    }
 */


    /* .enquireform_modal .modal-content {
        left: 0px !important;
    } */



    .projectdetails_description {
        width: 100% !important;
    }

    .project_highlights_main .btn-get-started {
        width: 295px !important;
    }



    .project_highlights_title {
        font-weight: 500 !important;
        font-size: 24px;
    }
}

.banner_image .slider_description_card {
    text-align: left;
    position: absolute !important;

}

.project_highlights_heading {
    font-family: MADE Coachella;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.23;
    letter-spacing: 1.76px;
    text-align: center;
    color: #362a82;
}

.rera_no {
    color: #303030;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.project_highlights_main .btn-get-started {
    width: 315px;
    height: 40px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: #ffbe00;
    text-align: center;
}

.projectdetails_description {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: .28px;
    color: rgba(0, 0, 0, .6);
}

.description2 {
    text-align: center;
    width: 905px;
    margin: 16px auto 0;
}

.description3 {
    color: #7b5f18;
    font-size: 16px;
    font-weight: 500;
}

.navbar-toggler {
    position: relative !important;
    padding: 0.25rem 0.75rem !important;
    font-size: 1.25rem !important;
    line-height: 1 !important;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 0.25rem !important;
}

.navbar-toggler span {
    display: block;
    background-color: #000;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
}

.navbar-toggler:not(.collapsed) span:first-child {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(135deg);
    opacity: .9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: initial;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(-135deg);
    opacity: .9;
}

.navbar-toggler span:first-child,
.navbar-toggler span:nth-child(3) {
    transition: transform .35s ease-in-out;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.project_highlights_main .row {
    margin-top: 74px;
}

.project_highlights_main .projecticon-box .projecticon {
    width: 72px;
    margin: 0 auto;
}

.projecticon-box {
    padding-bottom: 10%;
}

.project_highlights_title {
    font-weight: 700;
}

.plans .plans-Rectangle {
    text-align: center;
    width: 60%;
    height: auto;
    border-radius: 8px;
    background-color: rgba(243, 129, 92, 0.2);
    margin-left: 39%;
}

/* .plans-Rectangle div {
    background-color: rgba(243, 129, 92, 0.2);
} */

.plans-icons {
    margin-top: 38px;
    margin-bottom: 11px;
}

.active_plans_div {
    height: 177px;
    border-radius: 8px;
    cursor: pointer;
}

.active_plans {
    background-color: rgba(243, 129, 92, 0.2);
    border-radius: 8px;
    cursor: pointer;
}


@media only screen and (max-width: 960px) and (orientation : landscape) {
    iframe {
        width: 100%;
        height: 90vh;
        margin-top: 10px;
    }
}

.rotatemodal .close {
    padding: 0;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.rotatemodal .modal-content {
    border-radius: 16px !important;
    background-color: #ffbe00 !important;
}

.rotatemodal .modal-body {
    padding: 20px !important;
}

.rotatemodal .modal-card {
    text-align: center;
}

.enquireform_modal .modal-content {
    width: 76%;
    left: 9%;
}


.enquiryform_body {
    padding: unset !important;
    background-color: #ebeaf2;
    border-radius: 12px;

}

.enquire_now_heading {
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 18px;
    line-height: 27px;
    color: #444444;
    padding-top: 24px;
}

.enquireform {
    padding: 12px;

}

.enquireform input {
    border: none;
    border-radius: unset;
    border-bottom: 1px solid #383838;
}

.enquireformbtn_group {
    display: flex;
    justify-content: center;
    padding-top: 18px;
    /*   */
}

.submit_btn {
    background: #362A82 !important;
    border: 1px solid #362A82 !important;
    color: #fff !important;
    padding: 7% 35% !important;
}

.cancel_div {
    /*margin-right: 17%;
    margin-left: -14%;
      width: 100%; */
}

.cancel_btn {
    background: transparent !important;
    border: 1px solid #362A82 !important;
    padding: 7% 35% !important;
    color: #362A82 !important;
}

::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #888888;
}

.otp_text {
    width: 316px;
    height: 17px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #362A82;
    cursor: pointer;
}

.enquire_form_desc {
    width: 316px;
    height: 72px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #444444;
}

.btn_text {
    width: 56px;
    height: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.08em;
    color: #362A82;
}

.btn_group .button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    width: 88px;
    height: 34px;
    border: 1px solid #362A82;
    border-radius: 4px;
}

.enquire_form_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 18px;
    width: 316px;
    height: 124px;
}

.categorydetail {
    max-width: 1140px !important;
}

.margin-top {
    margin-top: 74px;
}

.categorydetail .card {
    margin-top: -50px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 10%);
}

.banner_image img {
    height: 90vh !important;
}

.categorydetail_row {
    margin-left: 25% !important;
}

.projects-hr {
    border: 1px solid #383838;
    width: 90%;
    margin-left: -130px;
    float: left;
    position: absolute;
    z-index: 1;
}

.category_projects .dropbtn {
    color: #000 !important;
    padding: 8px;
    border-radius: 4px !important;
    font-size: 16px !important;
    cursor: pointer !important;
    border: 1px solid #383838 !important;
    float: right;
    position: absolute;
    right: 0;
}

.category_projects .btn-get-started {
    width: 132px;
    height: 40px;
    font-display: swap;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    padding: 12px 16px;
    border-radius: 4px;
    transition: .5s;
    line-height: 1;
    color: #fff;
    animation-delay: .8s;
    border: 0;
    background-color: #362a82;
}
.category_projects .book-now{
    margin-top: 0px;
}


.category_projects .card {
    height: fit-content;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 10%);
    margin-top: 30px;
    cursor: pointer;
}

.category_projects .project_img {
    border-radius: 8px;
    object-fit: cover;
    height: 341px;
    -o-object-fit: cover;
}

.progresss {
    width: 100px !important;
    border-radius: 8px !important;
    position: absolute;
    right: 12px;
    z-index: 1;
    margin-top: 30px;
    width: fit-content !important;
    block-size: fit-content;
}

.card_title {
    font-weight: 600;
    font-size: 24px;
    color: #383838;
}

.card-location {
    font-size: 15px;
    font-weight: 500;
    color: #383838;
}

.view-details {
    width: 95% !important;
    float: left;
}

.view-details1 {
    width: 95% !important;
    background-color: #fff !important;
    color: #362a82 !important;
    border: 1px solid #362a82 !important;
}
.project_highlights_main .book_now a{
    color: #fff;
    text-decoration: none;
    padding: 15px;
}