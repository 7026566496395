html,body{
  height: 100%;
}
#navbarira{
  background-color: #fff !important;
}
.overlay {
  position: absolute;
  width: 444px;
  /*  width: 92%; */
  height: fit-content;
  /* height: 277px; */
 /*  top: 21%;
  left: 10%; */
  z-index: 1;
  text-align: center;
  border-radius: 6px;
  line-height: 100px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.6);
}


.overlay .card-title {
  padding: 23px 20px 10px 40px;
  font-display: swap;
  font-family: MADE Coachella;
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 54px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(254, 254, 255, .87);
  text-transform: uppercase;
}

.overlay1 .card-title {
  padding: 23px 20px 10px 40px;
  font-display: swap;
  font-family: MADE Coachella;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 54px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(254, 254, 255, .87);
  text-transform: uppercase;
}

.slideshow-card-description {
  font-display: swap;
  font-family: Poppins;
  font-size: 15px;
  /* font-stretch: normal; */
  font-style: normal;
  line-height: 27px;
  text-align: left;
  color: hsla(0, 0%, 100%, .87);
  padding: 0 15px 0 40px;
  font-weight: 400;
  letter-spacing: -0.01em;
}

/* .slideshow-card-description2 {
  margin-bottom: 0;
  padding: 0 38px 0 40px;
  font-weight: 500;
  letter-spacing: 1.2px;
} */

.category_projects {
  padding-bottom: 37px;
}

.overlay .slider_enquirebtn {
  text-align: left;
  padding: 0 15px 10px 40px;
  line-height: 73px;
  margin-top: -3%;
}

.glimpse_textDiv {
  padding-right: 17%;
}


.navbar-nav .show>.nav-link,
.navbar-nav .nav-link.active {
  color: #007BFF !important;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.container {
  max-width: 1140px !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header {
  /* width: 250px; */
  /* height: 98px; */
  margin: 30px 100px 30px 0;
  font-family: 'MADE Coachella';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: 0.03em;
  color: #362A82;
}

.section_margin {
  margin-top: 80px;
}

.footer_enquiry {
  background: #EBEAF2;
  padding: 16px 24px 24px 24px;
  border-radius: 8px;

}

.navbar-collapse {
  flex-grow: unset !important;
  /* width: 814px; */

}


.navbar_bg {
  height: 60px !important;
}

.nav-link {
  font-family: Poppins;
  font-style: normal;
  color: #222222 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding-right: 18px !important;
}

.navbar {
  position: fixed !important;
  /*  opacity: .8; */
  z-index: 1030;
  width: 100%;
  --bs-navbar-color: none !important;
}

.navbar a:hover {
  color: #106eea !important;
}

/*blink blog and div*/
.blinker {
  position: relative;
}

.blogBlink {
  position: absolute;
  animation: blinker 1s infinite;
}

.mediaBlink {
  animation: blinker 1s .5s infinite;
}


@keyframes blinker {
  50% {
    opacity: 0;
  }

}


.slider_description_card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-align: left;
  position: absolute !important;
  height: 360px !important;
  left: 10%;
  top: 134px;

  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3) !important;
  backdrop-filter: blur(10px) !important;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 6px !important;
  width: 30%;
}

.slider_description_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  text-overflow: ellipsis;


}

.slider_description_title {
  /* width: 278px !important;
  height: 54px !important; */
  font-family: 'MADE Coachella' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #FFFFFF;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.slider_description_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* gap: 8px; */
  /* width: 380px;
  height: 170px; */
}

.slider_description_title_body {
  /* width: 380px;
  height: 108px; */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */

  letter-spacing: -0.01em;

  color: #FFFFFF;
}

.slider_button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 20px 8px;
  gap: 10px;
  width: 142px;
  height: 39px;
  background: #362A82;
  border-radius: 4px;
  /* margin-top: -10px; */
}

.enquire_now {
  width: 98px;
  height: 24px;
  font-family: 'Poppins';
  font-style: normal;
  /* font-weight: 600; */
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  letter-spacing: 0.03em;
  color: #FFFFFF;
}

.slider_indicators {
  bottom: 0;
  position: absolute;
  /* display: flex; */
  height: 40px;
  right: 600px;
  width: 148px;
  padding: 10px 24px 11px;
  background-color: #ffbe00;
}

.slider_text {
  right: 0;
  width: 600px;
  padding: 10px 12px 11px;
  background-color: aliceblue;
  bottom: 0;
  position: absolute;
  display: flex;
  height: 40px;
  color: #222;
}

.arrowleft {
  padding-bottom: 3px;
  font-size: 25px;
  color: #362A82;
  font-weight: bold;
}

.arrowright {
  padding-bottom: 3px;
  font-size: 25px;
  color: #362A82;
  font-weight: bold;
}

.active_index {
  font-family: Poppins;
  margin-left: 5px;
  font-size: 14px;
  width: 100%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1.12px;
  text-align: left;
  color: #383838;
}

.carousel-caption {
  bottom: 0px !important;
  /* right: 0px !important; */
}

.carousel-inner>.item {
  width: 100%;
}

.plans_iframe {
  /* position: absolute; */
  /* left: 320px; */
  /* height: 532px; */
  margin-left: -15px;
}


.glimpse_header {
  width: 250px;
  height: 98px;
  margin: 30px 100px 30px 0;
  font-family: 'MADE Coachella';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: 0.03em;
  color: #362A82;
}

.glimpse_decription {
  width: 428px;
  height: 243px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #383838;
}

.About-background {
  height: 267px;
  margin-left: 8%;
}

.About-background,
.About-background-1 {
  width: 100%;
  height: 70%;
  /* margin: 8% 0 0 0; */
  object-fit: contain;
  border-radius: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}


.Rectangle-9-copy-3 {
  width: 247px;
  height: 96px;
  margin: 0px 44px 55px 0px;
  padding: 10px 30px;
  border-radius: 8px;
  box-shadow: 10.6px 10.6px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1.5px #362a82;
  background-color: #362a82;
}

.Rectangle-9-copy-4 {
  margin: 0 0 0 60%;
  background-color: #f3815c;
  border: solid 1.5px #f3815c;
  width: 219px;
  height: 96px;
}



.Rectangle-9-copy {
  margin: 55px 46px 0px 0;
  background-color: #ffbe00;
  border: 1.5px solid #ffbe00;
}

/* .-Cities-Presence-Pan-India {
  width: 183px;
  height: 44px;
  font-display: swap;
  font-weight: normal;
  font-family: 'MADE Coachella';
  font-size: 24px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.64px;
  text-align: left;
  color: #ffffff;
} */


.Glimpse-Ira-text2 {
  font-size: 14px;
  font-family: 'MADE Coachella';
}









/* -------------icon----------------- */

.whyicon_box img {
  width: 72px;
  height: 72px;
}

.dropdown-menu {
  --bs-dropdown-min-width: 11.3rem !important;
}

.services .icon-box .icon {
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 28px;
}

/* --------------title------------------ */
.title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

.dropdown-toggle::after {
  display: none !important;
}

.featured-project-card1-header {
  cursor: pointer;
  color: rgba(0, 0, 0, .12);
  margin-top: 24px;
  font-size: 30px;
  font-weight: 500;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  text-transform: uppercase;
}

.featured-projects-hr {
  width: 182px;
  height: 1px;
  margin: 23px 2px 24px 1px;
  opacity: .06;
  border: 1px solid #000;
  background-color: #000;
}

.featured-project-card1-option {
  margin-top: 18px;
  font-display: swap;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #303030;
}

/* .Featured-Projects-card2-background-Image {
  background-size: 100% 100%;
  width: 1022px;
  height: 530px;
  margin: 30px 0 30px 10px;
  padding: 0 0 86px;
  object-fit: contain;
  border-radius: 8px;
} */

.Featured-Projects-card2-background {
  margin: 0 484px 44px 0;
  background-color: #ffbe00 !important;
}

.featured-projects-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #303030;
  line-height: 30px;
  letter-spacing: -0.01em;
}

.featured-projects-subdescription {
  font-weight: 300;
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #303030;
}

.featured-projects-description {
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  font-size: 14px;
  font-weight: 200;
  color: #303030;
}

.btn-get-started {
  /* margin: 10px 73px 0 0; */
  font-display: swap;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 16px;
  border-radius: 4px;
  transition: .5s;
  width: 67%;
  line-height: 1;
  /* margin-left: 40px; */
  cursor: pointer;
  margin-top: 20px;
  color: #fff;
  border: 0;
  background-color: #362a82;
}



.services .title-box h3 {
  /* font-weight: 300; */
  margin-bottom: 15px;
  font-size: 24px;
  color: #383838;
  transition: .3s ease-in-out;
  line-height: 1.2;
}

/* -----------------description---------------------- */
.description {
  position: absolute;
  width: 1140px;
  height: 513px;
  left: 150px;
  top: 2194px;
  padding-top: 3%;
}

.description1 {
  padding-top: 3%;
}

/* -------------------page----------------------- */
.services .icon-box {
  width: 100%;
  height: 480px;
  margin-right: 0;
  text-align: left;
  border-color: #fff;
  padding: 48px 20px;
  transition: all .3s ease-in-out;
  background: #fff;
  border-radius: 8px;
  /* transition-delay: .2s; */

}

.whyicon_box {
  padding: 32px 24px;
  height: 524px;
  /* height: fit-content; */
  background: #FFFFFF;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  align-items: flex-start;
  flex-direction: column;
}

.whyIra img {}




.whyIra .description1 {
  /* width: 225px; */
  line-height: 20px;
  font-weight: 400;
}

/* .row {
  display: flex;
  margin-right: 0 !important;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-left: -15px;
  box-sizing: border-box;
} */

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);

}



.welcome_header {
  font-family: MADE Coachella;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.23;
  letter-spacing: 1.76px;
  text-align: left;
  color: #362a82;
  text-transform: uppercase;
  /* margin-top: 10%; */
}

.privacy_header {
  font-family: MADE Coachella;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.23;
  letter-spacing: 1.76px;
  text-align: center;
  color: #362a82;
  text-transform: uppercase;
  margin-top: 10%;
}

.welcome_head_description {
  padding-left: 19px !important;
  padding-top: 5%;
}

.tag_line {
  color: #383838;
  font-style: italic;
  font-weight: 500;
  padding-right: 37%;
  letter-spacing: 0.03em;
  line-height: 34px;
  font-family: 'Poppins';

}

.read_link {
  color: #362a82;
  outline: 0;
  text-decoration: none;
}

.welcome_decription {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: .28px;
  text-align: left;
  color: rgba(0, 0, 0, .6);
}

.privacy_decription {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: .28px;
  /* text-align: left; */
  color: rgba(0, 0, 0, .6);
}

.welcome_ira {
  display: flex;
  flex-direction: row;
}

.welcome_ira_img {
  width: 80%;
  /* height: 70%; */
  margin: 30px;
  /* padding: 0px 16px; */
  border-radius: 12px;
  margin-top: 13%;
  margin-right: 1%;
  margin-left: 10%;
  margin-bottom: 7%;
}

.welcome_ira_background_img {
  background-image: url(../public/assets/Welcome-To-Ira-background.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.Vision_Mission {
  background-color: rgba(243, 129, 92, .1);

}

.vision_img {
  max-width: 100%;
  height: auto;
  margin-bottom: -30px;
  margin-top: -45px;

}

.vision_img_mobile {
  width: inherit;

}


.vision_Mission_row {
  margin-left: 0px;
  margin-right: 0px;
}

.vision_head_description {

  padding-right: 0;
  padding-left: 0;
}

.icon_box {
  margin-top: 20px;
  width: 311px
}


.icon-about {

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

}

.icon_img {
  padding: 22px;
  border-radius: 8px;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 10%);

}



.Team-Founder-Name2 {
  color: #362a82;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 50px;
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: .28px;
}

.Founder-Name2-Role {
  text-align: center;
  color: rgba(0, 0, 0, .6);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: .28px;
}

.flip-box-back {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #362a82;
  border-radius: 8px;
  box-shadow: 7.1px 7.1px 16px 0 rgb(0 0 0 / 10%);
  transform: rotateY(180deg);
}

.Team_description2 {
  text-align: left;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: .28px;
  padding: 6%;
}

.Founder-Name {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: .28px;
  /* margin-top: 50px; */
  margin-bottom: 0px;
}

.Founder {
  font-weight: 300;
  padding-bottom: 23px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: .28px;
}

.About_wrapper {
  display: inline-block;
}

.About_wrapper:hover .About_card {
  transform: rotateY(180deg);
  box-shadow: 7.1px 7.1px 16px 0 rgb(0 0 0 / 10%);
}


.privacy_section {
  margin-bottom: -2%;
  background-color: rgba(54, 42, 130, 0.06);
}

.About_card {
  border-radius: 8px;
  background-color: initial;
  width: 300px;
  height: 500px;
  position: relative;
  transform: perspective(1000px);
  transform-style: preserve-3d;
  transition: all 0.5s linear;

}

.About_back,
.About_front {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backface-visibility: hidden;
  box-shadow: 7.1px 7.1px 16px 0 rgb(0 0 0 / 10%);
  padding: 9px 0px 22px 0px
}

.About_back {
  transform: rotateY(180deg);
  background-color: #362a82;
  border-radius: 8px;
  box-shadow: 7.1px 7.1px 16px 0 rgb(0 0 0 / 10%);
}





.flip-card {
  border-radius: 8px;
  background-color: initial;
  width: 380px;
  height: 420px;
  perspective: 1000px;
}

.flip-box-inner {
  box-shadow: 7.1px 7.1px 16px 0 rgb(0 0 0 / 10%);
  text-align: center;

}

.flip-box-front {
  padding: 11px 15px 22px 14px;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 7.1px 7.1px 16px 0 rgb(0 0 0 / 10%);
}

.manager-img {
  padding: 0;
  margin: 0;
  border-radius: 8px;
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.about_prev_next_button {
  text-align: end;
  margin-top: 30px;
}

.About_prev_button {
  border: none;
  color: #362A82;
}

/* murali css starts here */
.IRA-Realty-carousel1 {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 100% 0;
  background-repeat: no-repeat;
  overflow: hidden;
  object-fit: cover;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  -o-object-fit: cover;
  font-family: 'object-fit: contain;';
}
/* .category_projects .project_img {
  height: 341px;
} */

/* murali css ends here */





/* .About-background {
  height: 267px;
  margin-left: 8%;
}

.About-background,
.About-background-1 {
  width: 80%;
  height: 70%;
  
  object-fit: contain;
  border-radius: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
} */

/* .Rectangle-9-copy-4 {
  margin: 0 0 0 60%;
  background-color: #f3815c;
  border: solid 1.5px #f3815c;
  width: 219px;
  height: 96px;
} */


.-Cities-Presence-Pan-India {
  width: 183px;
  height: 44px;
  font-display: swap;
  font-weight: normal;
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.64px;
  text-align: left;
  color: #ffffff;
}

.Glimpse-Ira-text1 {
  font-size: 24px;
}

.Glimpse-Ira-text2 {
  font-size: 14px;

}



.dropbtn {
  background-color: #fff !important;
  color: #000 !important;
  width: 182px;
  height: 42px;
  padding: 8px;
  border-radius: 8px !important;
  font-size: 16px !important;
  border: none !important;
}



.featured_projects_title {
  font-family: 'MADE Coachella';
  font-size: 40px;
  font-weight: 500;
  /* margin-left: 134px; */
  font-stretch: normal;
  font-style: normal;
  line-height: 54px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #362a82;
  text-transform: uppercase;
}

.featured_projects_left {
  height: 555px;
  margin-top: 30px;
  /* position: absolute; */
  right: 29px;
  padding: 24px 34px 30px 35px;
  border-radius: 8px;
  background-color: rgba(243, 129, 92, .2);
  /* overflow: auto; */
  width: 254px;
}

.featured_projects_left::-webkit-scrollbar {
  display: none;
}

.dropdown-menu {
  --bs-dropdown-min-width: 11.3rem !important;
}


.dropdown-toggle::after {
  display: none !important;
}

.featured_projects_padding {
  padding-left: 30px !important;
}

.featured-project-card1-header {
  cursor: pointer;
  color: rgba(0, 0, 0, .12);
  margin-top: 24px;
  font-size: 30px;
  font-weight: 500;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  text-transform: uppercase;
}

.featured-projects-hr {
  width: 182px;
  height: 1px;
  margin: 23px 2px 24px 1px;
  opacity: .06;
  border: 1px solid #000;
  background-color: #000;
}

.featured-project-card1-option {
  margin-top: 18px;
  font-display: swap;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #303030;
}

.Featured-Projects-card2-background-Image {
  background-size: 100% 100%;
  /* width: 930px; */
  height: 555px;
  margin: 30px 0 30px 10px;
  padding: 0 0 86px;
  object-fit: contain;
  border-radius: 8px;
}

.Featured-Projects-card2-background {
  margin: 0 510px 44px 0;
  background-color: #ffbe00 !important;
}

/* .featured-projects-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #303030;
} */

.flip-box-back {
  background-color: #362a82;
  color: cornsilk;
  border-radius: 8px;
  box-shadow: 7.1px 7.1px 16px 0 rgb(0 0 0 / 10%);
  transform: rotateY(180deg);
}

.project-amenities-Rectangle {
  display: flex;
  align-items: stretch;
  width: 100%;
  flex-direction: column;
  padding: 0 35px;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  margin-right: 3%;
  /* Card Shadow */

  box-shadow: 7.1px 7.1px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.project-selected-feature {
  width: 100%;
  background-color: #fff;
  padding: 0 35px;
  border: 1px solid #ECECEC;
  /* height: 330px; */
  box-shadow: 7.1px 7.1px 16px rgba(0, 0, 0, 0.06);
  /* border: 1px solid #ececec; */
}

.amenities-selected-project {
  padding: 13px 0;
}

.project-amenities-description {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: .28px;
  text-align: left;
  border: none;
  background-color: transparent;
}

.project-selected-feature_mobile {
  display: flex;
  width: 100%;
  color: #fff !important;
  /* width: 30%; */
  text-align: center;
  font-size: 14px;
}



.project-amenities-Rectangle1 {
  display: flex;
  align-items: stretch;
  width: 100%;
  background: #FCF3EF;
  /* Card Shadow */

  box-shadow: 7.1px 7.1px 16px rgba(0, 0, 0, 0.06);
  /* padding: 35px; */
}

.project-amenities-Rectangle2 {
  /* display: flex; */
  align-items: stretch;
  width: 100%;
  padding: 35px;
}

.project-amenities-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #383838;
}

.about_welcome {
  margin-top: 80px;
  padding-left: 34px !important;
  padding-right: 16px !important;
}

.project-amenities-Rectangle_mobile {
  display: flex;

  align-items: stretch;
  align-items: center;
  /* background: #FFFFFF; */
  background: #FEF4F1;

  /* border: 1px solid rgba(0, 0, 0, 0.6); */
  border-radius: 4px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  /* justify-content: center; */
  /* margin: 24px 0; */
}

.project-about_welcome {
  background-color: rgba(243, 129, 92, .1);
  margin-top: 4%;
  padding-top: 3%;

}

.slider_enquirebtn .slider_button {
  margin-top: -17px !important;
}

.loading-indicator {
  position: absolute;
  background-image: url(../public/assets/Loading.gif);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  z-index: 9999;
}

.enquireBtn {
  background-color: rgb(54, 42, 130) !important;
  border: none !important;
  padding: 8px 21px !important;
  border-radius: 4px !important;
}

.icon-box {
  max-width: 29%;
  padding-bottom: 8%;
}

.whyira_body {
  padding: 0% 8%;
}

.overlay1 {
  width: 520px;
  height: 154px;
  position: absolute;
  top: 21%;
  left: 10%;
  z-index: 1;
  text-align: center;
  border-radius: 6px;
  line-height: 100px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.6);
}

.plans_row {
  display: flex;
  margin-right: 0 !important;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-left: -15px;
  box-sizing: border-box;
  ;
}

.about_welcome .welcome_decription {
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #383838;
  padding-right: 0%;
}

.privacy_section {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.privacy_section_text {
  padding: 1% 10% 0% 10% !important;
}

.footer_enquiry .form-control {
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #383838;
  padding: 10px 8px;
}

.input_padding {
  margin-bottom: 15px;
}

.btn_margin {
  margin-left: 4%;
}

@media (max-width: 2560px) {
  .overlay {
    left: 2%;
    top: 15%;
  }
  

  .banner_image .slideshow-card-description {
    padding: 0 15px 23px 40px;
  }
}



@media (max-width : 1049px) {
  .whyicon_box {
    height: 586px !important;
  }

}

@media (width : 1024px) {
  .Featured-Projects-card2-background {
    margin: 0 431px 44px 0;
  }

  .-Cities-Presence-Pan-India {
    font-size: 14px;
  }

  .-Cities-Presence-Pan-India .Glimpse-Ira-text2 {
    font-weight: 200;
  }

  .Rectangle-9-copy-4 {
    margin-left: 251px;

  }

  .Rectangle-9-copy-3 {
    width: 200px;
    height: 96px;
    padding: 10px 10px;
  }

  .Rectangle-9-copy {
    margin-top: 80px;
  }


}

@media (max-width : 1023px) {
  .glimpse_text p {
    text-align: center !important;
  }

  .category_projects .featured_projects_title {
    font-size: 32px;
    line-height: 1.23;
  }
  .category_projects .project_img{
    height: 241px;
  }
}
@media (min-width: 991px) {
  
}
@media (max-width: 991px) {
  #basic-navbar-nav{
    margin-bottom: 16px;
  }
  .overlay {
    top: 18%;
    left: 5%;
  }
  .privacy_section {
    margin-bottom: -6% !important;
    /* margin-top: 60px !important; */
  }
  
  .category_projects .project_img{
    /* object-fit: contain !important; */
    height: 205px !important;
  }

  .navbar .container {
    background-color: #fff !important;
  }

  .nav-link {
    padding: 12px 5px !important;
  }

  .glimpse_textDiv {
    text-align: center;
    padding-right: unset;
    /* padding: 0% 5%; */
  }

  .project-text-overflow {
    padding: 8px 10px 4px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }

  .header {
    font-weight: 500;
    font-size: 24px;
  }

  .welcome_head_description {
    margin-bottom: 24px;
  }

  .glimpse_textDiv .welcome_decription {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }

  .whyicon_box,
  .homeRevolution .welcome_decription,
  .homeRevolution .welcome_header {
    text-align: center;
  }

  .homeRevolution .about_welcome {
    padding-left: unset !important;
    text-align: center;
  }

  .homeRevolution .tag_line {
    padding-right: unset !important;
    text-align: center;
  }

  .glimpse_img {
    padding: 0% 26% !important;
  }

  .Rectangle-9-copy-4 {
    width: 229px;
    margin: 0 0 0 61%;
  }

  .Rectangle-9-copy {
    margin: 54px 46px 54px 0;
  }

  .whyicon_box {
    height: 430px !important;
  }

  .whyicon_box .icon {
    display: flex;
    justify-content: center;
  }

  .whyIra .title1 {
    height: 72px;
    padding: 0% 25%;
    margin-bottom: 4%;
  }

  .-Cities-Presence-Pan-India .Glimpse-Ira-text2 {
    font-weight: 200;
  }
}

@media (max-width: 960px) {
  .welcome_ira_background_img {
    background-size: 100% 100%;
  }

  .compdonent {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .about_prev_next_button {
    text-align: center;
  }

  /* html,
    body {
      margin: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
    } */

  .welcome_header {
    text-align: center;
    font-size: 24px;
  }

  .para_welcome {
    text-align: center !important;
  }

  .welcome_decription {
    text-align: center !important;
    font-size: 14px;
  }

  .privacy_header {
    font-size: 24px;
    margin-top: 20%;
  }


  .tag_line {
    text-align: center !important;
    line-height: 1.93;
    letter-spacing: .28px;
  }




  /* .glimpse_textDiv .para_welcome{
    padding-left: 6%;
  } */

  /* .vision_img {
    position: relative;
    top: 2px !important;
  }
  
    
      margin-top: -45px ;
      
      margin-bottom: -20px;
    
  } */
}

@media (min-width: 960px) {
  .about_welcome .tag_line {
    font-size: 16px;
    font-weight: 700;
  }
  .About_card{
    width: 324px;
  }
}

.about_us .card-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.01em;
  text-transform: uppercase;

  color: #FFFFFF;

}

.about_us .slideshow-card-description {
  font-family: 'MADE Coachella';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #FFFFFF;
}

.about_us .tag_line {
  padding-right: 0% !important;
}

@media (max-width: 881px) {
  .whyIra .title1 {
    padding: 0% 21%
  }
}

@media (max-width: 768px) {
  #basic-navbar-nav{
    margin-bottom: 47px;
  }
  .glimpse_img {
    padding: 0% 20% !important;
  }

  .welcome_head_description {
    margin-bottom: 24px;
  }

  .welcome_ira_img {
    width: 80%;
  }

  .vision_head_description {
    padding-bottom: 7%;
  }

  .welcome_ira_background_img {
    margin: 0px 16px;
  }

  .read_div {
    text-align: center !important;
  }

  .about_margin {
    margin-top: 60px;
  }

  .about_us .slideshow-card-description {
    font-size: 14px !important;
  }



  .slider_description_card {
    width: 40%;
    height: 279px !important;
    gap: 8px !important;
  }

  .slider_description_title {
    font-size: 32px !important;
    margin-bottom: -2% !important;
  }

  .slider_description_title_body {
    font-size: 14px !important;
    line-height: 22px;
    /* height: 110px; */
  }

  /* .slider_button {
    margin-top: -17px !important;
  } */
  .slider_enquirebtn .slider_button {
    margin-top: -17px !important;
  }

  .navbar-collapse {
    height: 445px;
  }

  #basic-navbar-nav .enquireBtn {
    width: 32%;
    padding-bottom: 2%;
  }

  #basic-navbar-nav {
    padding-bottom: 2%;

  }

  .slider_indicators {
    width: 148px !important;
  }

  .A-Glimpse-Into-IRA {
    /* margin: 30px 30px 30px 0; */
    /* padding: 0% 22%; */
    text-align: center;
    margin: unset;
  }

  .glimpse_text {
    padding: 0% 5% 5% 5% !important;
  }

  .whyira_body {
    text-align: center !important;
  }

  .whyIra .icon_box {
    text-align: center !important;
  }

  .glimpse_textDiv .welcome_decription {
    letter-spacing: 0.02em;
    line-height: 27px;
    font-weight: 400;
    padding: 0px 16px;
  }

  .glimpse_textDiv {
    padding-right: unset;
  }

  .about_welcome .tag_line {
    padding: 0;
  }

  .about_welcome .welcome_decription {
    padding: 0% 4%;
  }

  .whyIra .title1 {
    padding: 0% 20%;
  }

  .whyicon_box .icon {
    display: flex;
    justify-content: center;
  }

  .privacy_section {
    padding: 20px 16px;
  }
}

@media (max-width: 600px) {
  .whyicon_box {
    height: auto;
  }

  .glimpse_header {
    font-size: 24px;
    text-align: center;
    margin: 0px !important;
    width: auto;
  }

  .slider_description_card {
    left: 4% !important;
  }

  .glimpse_decription {
    width: auto;
    text-align: center;
  }

  .Glimpse-Ira-text1 {
    font-size: 16px !important;
  }

  .Glimpse-Ira-text2 {
    font-size: 10px !important;
  }

  .Rectangle-9-copy-4 {
    width: 167px !important;
  }



  .slider_description_card {
    height: 300px !important;
    width: 86%;
  }

  #basic-navbar-nav .enquireBtn {
    width: 59%;
  }

  .overlay {
    width: 92% !important;
   /*  left: 4%;
    top: 110px; */
    height: fit-content;
  }

  .overlay1 {
    width: 258px !important;
    height: 165px !important;
    left: 4%;
    top: 110px;
  }

  /* .amenities_plans {
    padding-top: 36px;
    padding-bottom: 18px;
  } */

  .overlay .card-title {
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 0.03em;
    line-height: 43px;
  }

  .overlay1 .card-title {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    line-height: 43px;
  }

  .slideshow-card-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    /* width: 372px; */
    width: 92%;
  }

  .slider_indicators {
    width: 100% !important;
  }

  .header {
    line-height: 32px;
    text-align: center;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 7%;
  }

  .glimpse_text p {
    text-align: center !important;
  }

  .glimpse_img {
    margin-bottom: -11% !important;
    padding: 7% 18% !important;
  }

  .section_margin {
    margin-top: 60px;

  }

  .A-Glimpse-Into-IRA {
    padding: 0% 22%;
  }

  .about_welcome .welcome_header {
    padding: 0% 18%;
  }

  .about_welcome .tag_line {
    padding: 0% 17% !important;
  }

  .whyIra .title1 {
    padding: 0% 25%;
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .About-background {
    width: 73%;
    margin-left: 10%;
  }

  /* .whyicon_box {
    height: 400px;
  } */

  .whyicon_box img {
    width: 56px;
    height: 56px;
  }
}

@media (max-width: 425px) {
  .welcome_ira_background_img {
    background-size: 97% 100%;
  }

  .slider_description_card {
    height: 264px !important;
  }

  .Rectangle-9-copy-4 {
    margin: 0 0 0 48%;
    width: 175px !important;
  }

  .Rectangle-9-copy-3 {
    width: 193px;
  }

  #basic-navbar-nav .enquireBtn {
    width: 42%;
  }

  .navbar-brand img {
    width: 91%;
    padding-left: 2%;
  }

  .overlay {
    width: 92% !important;
  }

  .overlay .card-title {
    padding: 23px 20px 10px 25px;
  }

  .slideshow-card-description {
    padding: 0 15px 0 25px;
  }

  .overlay .slider_enquirebtn {
    padding: 0 15px 0 25px;
  }

  .banner_image .slideshow-card-description {
    padding: 0 15px 23px 26px;
  }

  /* .whyicon_box {
    height: 420px;
  } */
  .glimpse_img {
    padding: 0% 6% !important;
  }
}

@media (max-width: 375px) {
  .slider_description_card {
    height: 290px !important;
  }

  .overlay {
    width: 92% !important;
  }

  .slideshow-card-description {
    width: 339px !important;
  }

  .Rectangle-9-copy-3 {
    margin: 0px 44px 41px 0px;
  }

  .Rectangle-9-copy-4 {
    margin: 0 0 0 48%;

  }

  .Rectangle-9-copy {
    margin: 29px 44px 41px 0px;
  }

  .about_welcome .tag_line {
    padding: 0% 15% !important;
  }

  .whyIra .title1 {
    padding: 0% 21%;
  }

  .category_projects .featured_projects_title {
    font-size: 24px;
  }
}

@media (max-width: 320px) {
  .slider_description_card {
    height: 300px !important;
  }

  .overlay {
    width: 295px !important;
  }

  .slideshow-card-description {
    width: 281px !important;
  }

  .Rectangle-9-copy-3 {
    margin: 0px 44px 31px -28px;
  }

  .Rectangle-9-copy-4 {
    margin: 0 0 0 41%;
    width: 170px !important;
  }

  .Rectangle-9-copy {
    margin: 26px 44px 41px -28px;
  }

  .glimpse_img {
    padding: 7% 6% !important;
  }

  .about_welcome .tag_line {
    padding: 0% 8% !important;
  }

  .whyIra .title1 {
    padding: 0% 14%;
  }

  /* .whyicon_box {
    height: 471px;
  } */
}

.channelpartner{
  cursor: pointer;
}
.channelpartner:hover{
  color: #0a58ca;
}
